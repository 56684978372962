import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';

import { useNotification, useLoading } from '@/modules/core';

export function useTask(variables, options) {
  const { error } = useNotification();

  const { result, loading: queryLoading, refetch, onError } = useQuery(TASK_QUERY, variables, options);

  const task = computed(() => result.value?.taskNew2 ?? {});
  const loading = useLoading(queryLoading, variables);

  onError((param) => {
    console.error('tasks - useTask', param);
    error();
  });

  return {
    task,
    refetch,
    loading,
  };
}

const TASK_QUERY = gql`
  query task($id: ID!) {
    taskNew2(id: $id) {
      id
      businessId
      createdAt
      completedAt
      type
      domain
      activeAt
      activeBy: activeByNew {
        firstName: given_name
        lastName: family_name
      }
      data
    }
  }
`;
