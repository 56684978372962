import { useQuery } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';
import { computed } from 'vue';
import { gql } from '@apollo/client';

export const ALL_BUSINESSES = gql`
  query allBusinesses(
    $first: Int
    $after: Int
    $type: String
    $search: String
    $tenant: String
    $capabilities: BusinessCapabilitiesInput
  ) {
    businesses(
      first: $first
      after: $after
      type: $type
      search: $search
      tenant: $tenant
      capabilities: $capabilities
    ) {
      totalCount
      nodes {
        id
        name
        capabilities {
          restaurant
          bookkeeping
          supplier
          serviceProvider
        }
      }
    }
  }
`;

export function useAllBusinesses(variables) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(ALL_BUSINESSES, variables);

  const businesses = computed(() => result.value ?? {});

  onError((err) => {
    console.error('useBusinesses', err);
    error();
  });

  return {
    businesses,
    loading,
    refetch,
  };
}
