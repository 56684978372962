import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';

import { useNotification, useLoading } from '@/modules/core';

export const GROUP_ORDER_ITEMS_BY_BUSINESS = gql`
  query orderItemAggregationByBusiness($productId: ID, $fromDate: ISODate, $toDate: ISODate) {
    orderAggregation {
      groupOrderItemsByBusiness(productId: $productId, fromDate: $fromDate, toDate: $toDate) {
        nodes {
          business {
            id
            name
          }
          amount
          quantity
          orderCount
          productCount
          lastOrder {
            date
            order {
              products {
                productId
                discount
                price
              }
            }
          }
        }
      }
    }
  }
`;

export function useOrderItemAggregationByBusiness({ productId, fromDate, toDate }) {
  const { error } = useNotification();

  const variables = computed(() => ({
    productId: productId?.value,
    fromDate: fromDate?.value,
    toDate: toDate?.value,
  }));

  const { result, loading: queryLoading, onError, refetch } = useQuery(GROUP_ORDER_ITEMS_BY_BUSINESS, variables);

  const aggregation = computed(() =>
    result.value ? result.value.orderAggregation?.groupOrderItemsByBusiness?.nodes ?? [] : []
  );
  const loading = useLoading(queryLoading, variables);
  onError((err) => {
    console.error('useOrderItemAggregationByBusiness', err);
    error();
  });

  return {
    aggregation,
    loading,
    refetch,
  };
}
