import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery, useResult, useMutation } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';

import {
  TASK_COMPLETE_MUTATION,
  TASK_COMPLETE_MUTATION_NEW2,
  TASK_CREATE_MUTATION,
  TASK_CREATE_MUTATION_NEW2,
  TASKS_AGGREGATION_QUERY,
} from './graphql';

export function useTasks(variables, options) {
  const { error } = useNotification();

  const { result, loading: queryLoading, refetch, onError } = useQuery(TASKS_QUERY, variables, options);
  const taskConnection = useResult(result, { nodes: [], totalCount: 0 });
  const loading = useLoading(queryLoading, variables);
  const totalCount = computed(() => taskConnection.value.totalCount);
  const tasks = computed(() => taskConnection.value.nodes);

  onError((param) => {
    console.error('tasks - useTasks', param);
    error();
  });

  return {
    tasks,
    refetch,
    totalCount,
    loading,
  };
}

const TASKS_QUERY = gql`
  query tasks($businessId: ID!, $limit: Int, $offset: Int, $completed: Boolean, $domains: [String], $type: String) {
    tasksNew(
      businessId: $businessId
      limit: $limit
      offset: $offset
      completed: $completed
      domains: $domains
      type: $type
    ) {
      totalCount
      nodes {
        id
        createdAt
        completedAt
        completedBy {
          profile {
            firstName
            lastName
          }
        }
        referredObject {
          type
          order {
            id
            date
            supplier {
              id
              name
            }
            differences {
              amount
            }
            source {
              document {
                id
                documentNumber
                type: newType
              }
            }
          }
          product {
            id
            name
            sku
            business {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export function useTasksAggregation(variables) {
  const { error } = useNotification();

  const { result, loading: queryLoading, onError, refetch } = useQuery(TASKS_AGGREGATION_QUERY, variables);
  const aggregations = useResult(result, []);
  const loading = useLoading(queryLoading, variables);

  onError((param) => {
    console.error('useTasksAggregation', param);
    error();
  });

  return {
    aggregations,
    loading,
    refetch,
  };
}

export function useCompleteTask() {
  const { error } = useNotification();

  const { mutate, loading, onDone, onError } = useMutation(TASK_COMPLETE_MUTATION);

  onError((param) => {
    console.error('useCompleteTask', param);
    error();
  });

  return {
    completeTask: mutate,
    loading,
    onDone,
    onError,
  };
}

export function useCompleteTaskNew2() {
  const { error } = useNotification();

  const { mutate, loading, onDone, onError } = useMutation(TASK_COMPLETE_MUTATION_NEW2);

  onError((param) => {
    console.error('useCompleteTask', param);
    error();
  });

  return {
    completeTask: mutate,
    loading,
    onDone,
    onError,
  };
}

export function useCreateTask() {
  const { error } = useNotification();

  const { mutate, loading, onDone, onError } = useMutation(TASK_CREATE_MUTATION);

  onError((param) => {
    console.error('useCreateTask', param);
    error();
  });

  return {
    createTask: mutate,
    loading,
    onDone,
    onError,
  };
}

export function useCreateTaskNew2() {
  const { error } = useNotification();

  const { mutate, loading, onDone, onError } = useMutation(TASK_CREATE_MUTATION_NEW2);

  onError((param) => {
    console.error('useCreateTask', param);
    error();
  });

  return {
    createTask: mutate,
    loading,
    onDone,
    onError,
  };
}

const TASK_PATCH_MUTATION = gql`
  mutation taskPatch($taskId: ID!, $patchParams: TaskPatchInput!) {
    taskPatch(taskId: $taskId, patchParams: $patchParams) {
      id
      domain
      type
      data
      reviewRequired
    }
  }
`;

export function usePatchTask() {
  const { error } = useNotification();

  const { mutate, loading, onDone, onError } = useMutation(TASK_PATCH_MUTATION);

  onError((param) => {
    console.error('usePatchTask', param);
    error();
  });

  return {
    patchTask: mutate,
    loading,
    onDone,
    onError,
  };
}

export function usePatchTaskNew2() {
  const { error } = useNotification();

  const { mutate, loading, onDone, onError } = useMutation(TASK_PATCH_MUTATION_NEW2);

  onError((param) => {
    console.error('usePatchTask', param);
    error();
  });

  return {
    patchTask: mutate,
    loading,
    onDone,
    onError,
  };
}

const TASK_PATCH_MUTATION_NEW2 = gql`
  mutation taskPatch($taskId: ID!, $patchParams: TaskPatchInput!) {
    taskPatchNew2(taskId: $taskId, patchParams: $patchParams) {
      id
      domain
      type
      data
      reviewRequired
    }
  }
`;

export function useActivateTask() {
  const { error } = useNotification();

  const { mutate, loading, onError } = useMutation(TASK_ACTIVE_MUTATION);

  onError((param) => {
    console.error('useActivateTask', param);
    error();
  });

  return {
    activeTask: mutate,
    loading,
  };
}

const TASK_ACTIVE_MUTATION = gql`
  mutation taskActive($taskId: ID!, $activeParams: TaskActiveInput!) {
    taskActive(taskId: $taskId, activeParams: $activeParams) {
      id
      domain
      type
      data
      reviewRequired
      activeAt
    }
  }
`;

export function useActivateTaskNew2() {
  const { error } = useNotification();

  const { mutate, loading, onError } = useMutation(TASK_ACTIVE_MUTATION_NEW2);

  onError((param) => {
    console.error('useActivateTask', param);
    error();
  });

  return {
    activeTask: mutate,
    loading,
  };
}

const TASK_ACTIVE_MUTATION_NEW2 = gql`
  mutation taskActiveNew2($taskId: ID!, $activeParams: TaskActiveInput!) {
    taskActiveNew2(taskId: $taskId, activeParams: $activeParams) {
      id
      domain
      type
      data
      reviewRequired
      activeAt
    }
  }
`;
