import { gql } from '@apollo/client/core';

export const TASKS_QUERY = gql`
  query tasks($businessId: ID!, $limit: Int, $offset: Int, $completed: Boolean, $domains: [String], $type: String) {
    tasksNew2(
      businessId: $businessId
      limit: $limit
      offset: $offset
      completed: $completed
      domains: $domains
      type: $type
    ) {
      totalCount
      nodes {
        id
        createdAt
        completedAt
        completedBy {
          profile {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const TASKS_AGGREGATION_QUERY = gql`
  query taskAggregation($filters: TaskAggregationFilters, $options: TaskAggregationOptions) {
    taskAggregation(filters: $filters, options: $options) {
      businessId
      business {
        name
      }
      domain
      type
      count
      daysOpen
    }
  }
`;

export const TASK_COMPLETE_MUTATION = gql`
  mutation taskComplete($taskId: ID) {
    taskComplete(taskId: $taskId) {
      id
      completedAt
      completedBy {
        profile {
          firstName
          lastName
        }
      }
    }
  }
`;

export const TASK_COMPLETE_MUTATION_NEW2 = gql`
  mutation taskCompleteNew2($taskId: ID) {
    taskCompleteNew2(taskId: $taskId) {
      id
      completedAt
      completedBy {
        profile {
          firstName
          lastName
        }
      }
    }
  }
`;

export const TASK_CREATE_MUTATION = gql`
  mutation taskCreate($businessId: ID!, $domain: String, $data: JSON, $type: String) {
    taskCreate(businessId: $businessId, domain: $domain, data: $data, type: $type) {
      id
    }
  }
`;

export const TASK_CREATE_MUTATION_NEW2 = gql`
  mutation taskCreateNew2($businessId: ID!, $domain: String, $data: JSON, $type: String) {
    taskCreateNew2(businessId: $businessId, domain: $domain, data: $data, type: $type) {
      id
    }
  }
`;
