import { onMounted, onBeforeUnmount } from 'vue';
import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

export default (taskId, measureId, measureIntervals = 10000) => {
  const events = ['pointerdown', 'pointermove', 'keypress'];
  const { mutate: taskPing } = useMutation(TASK_PING_MUTATION);
  let movementDetected = false;
  let pingTimeInterval;
  let lastMovementTimestamp;

  onMounted(() => {
    pingTimeInterval = setInterval(handleIntervaleEnd, measureIntervals);
    for (const event of events) {
      document.addEventListener(event, activityRecorded);
    }
  });

  onBeforeUnmount(() => {
    clearInterval(pingTimeInterval);
    for (const event of events) {
      document.removeEventListener(event, activityRecorded);
    }
  });

  const handleIntervaleEnd = () => {
    if (movementDetected && taskId.value) {
      taskPing({
        taskId: taskId.value,
        measureIntervals,
        eventTimestamp: lastMovementTimestamp,
        measureId: measureId?.value,
      });
      lastMovementTimestamp = null;
    }
    movementDetected = false;
  };
  const activityRecorded = () => {
    movementDetected = true;
    if (!lastMovementTimestamp) lastMovementTimestamp = new Date();
  };
};

const TASK_PING_MUTATION = gql`
  mutation taskPing($taskId: ID!, $measureIntervals: Int!, $eventTimestamp: Date!, $measureId: String) {
    taskPing(
      taskId: $taskId
      measureIntervals: $measureIntervals
      eventTimestamp: $eventTimestamp
      measureId: $measureId
    )
  }
`;
