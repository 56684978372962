import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { omit } from 'lodash';

import { useNotification } from '@/modules/core';

export function useActiveTask(variables, options) {
  const { error } = useNotification();

  const isTaskIdProvided = computed(() => !!variables.value.taskId);

  const queryArgs = computed(() => ({
    variables: isTaskIdProvided.value ? { taskId: variables.value.taskId } : omit(variables.value, 'taskId'),
    query: isTaskIdProvided.value ? ACTIVE_TASK_BY_ID_QUERY : ACTIVE_TASK_QUERY,
  }));

  const {
    result,
    loading: activeTaskIsLoading,
    refetch: refetchActiveTask,
    onError,
  } = useQuery(queryArgs.value.query, queryArgs.value.variables, options);

  const activeTask = computed(() =>
    isTaskIdProvided.value ? result.value?.taskNew2 : result.value?.tasksNew2.nodes?.[0]
  );

  onError((e) => {
    console.error('tasks - useActiveTask', e);
    error();
  });

  return {
    activeTask,
    refetchActiveTask,
    activeTaskIsLoading,
  };
}

const ACTIVE_TASK_BY_ID_QUERY = gql`
  query activeTaskById($taskId: ID!) {
    taskNew2(id: $taskId) {
      id
      type
      businessId
      createdAt
      completedAt
      completedByNew {
        id
        email
        status
        given_name
        family_name
        locale
        picture
        phone_number
        memberships {
          id
          role
          businessId
          userId
        }
        firstName
        lastName
        profilePictureUrl
      }
      activeAt
      activeBy: activeByNew {
        id
        email
        status
        given_name
        family_name
        locale
        picture
        phone_number
        firstName
        lastName
        profilePictureUrl
      }
      domain
      data
      reviewRequired
      delivery {
        document {
          id
          businessId
          supplierId
          fromEmail
          pageCount
          supplier {
            id
            name
            countryCode
            legalName
            tenant
            number
            type
            logoUrl
          }
          issueDate
          type
          documentNumber
          allocationNumber
          orderReference
          deliveryDate
          filePath
          filePathUrl
          structureParams {
            documentNumber
            allocationNumber
            orderReference
            deliveryDate
            issueDate
            netAmount
            taxableAmount
            nonTaxableAmount
            taxRate
            openingBalance
            taxAmount
            totalAmount
            amountDue
            discountRate
            discountAmount
            paymentDueDate
            paidAmount
            paymentDate
            paymentMethod
            referencesFromDate
            referencesToDate
            items {
              name
              sku
              gtin
              price {
                includingTax
              }
              netPrice
              quantity
              quantityInPackage
              packageQuantity
              discountRate
              discountAmount
              totalDiscount
              totalTax
              totalDeposits
              totalPackages
              totalAmount {
                includingTax
                includingDiscount
              }
              reference
            }
            references {
              documentNumber
              issueDate
              orderReference
              deliveryDate
              paymentDueDate
              netAmount
              debitAmount
              creditAmount
              totalAmount
              balance {
                debit
              }
            }
            rounding {
              debit
            }
            generalCharges {
              name
              amount
            }
          }
          structureToken
          recorded
          isManual
          replicateOf
          hasDeclaration
          locale
          discountRate
          discountAmount
          rounding
          netAmount
          taxableAmount
          nonTaxableAmount
          taxRate
          openingBalance
          amountDue
          taxAmount
          totalAmount
          paymentDueDate
          paidAmount
          paymentDate
          paymentMethod {
            type
            creditCard {
              type
              number
            }
            cheque {
              number
              bank
              branch
              account
              dueDate
            }
            bankTransfer {
              bank
              branch
              account
            }
          }
          referencesFromDate
          referencesToDate
          generalCharges {
            name
            amount
          }
          items {
            sku
            gtin
            name
            quantity
            packageQuantity
            quantityInPackage
            price
            netPrice
            totalAmount
            discountRate
            discountAmount
            totalDiscount
            totalTax
            totalPackages
            totalDeposits
            productId
            reference
          }
          references {
            documentNumber
            orderReference
            deliveryDate
            netAmount
            totalAmount
            debitAmount
            creditAmount
            balance
            issueDate
            paymentDueDate
          }
          ocr {
            status
            originalPath
            originalPathUrl
            correctedPath
            correctedPathUrl
            abby {
              status
              originalPath
              originalPathUrl
              correctedPath
              correctedPathUrl
            }
            google {
              status
              generalPath
              generalPathUrl
              formPath
              formPathUrl
            }
          }
          createdBy
          createdUser {
            id
            email
            status
            given_name
            family_name
            locale
            picture
            phone_number
            firstName
            lastName
            profilePictureUrl
          }
          createdAt
          recordedBy
          recordedAt
          templateId
          source
          isBusinessValid
        }
      }
    }
  }
`;

const ACTIVE_TASK_QUERY = gql`
  query activeTask(
    $businessIds: [ID]
    $completed: Boolean
    $domains: [String]
    $types: [String]
    $reviewRequired: Boolean
  ) {
    tasksNew2(
      businessIds: $businessIds
      completed: $completed
      domains: $domains
      types: $types
      reviewRequired: $reviewRequired
      limit: 1
      offset: 0
    ) {
      nodes {
        id
        type
        businessId
        createdAt
        completedAt
        completedBy {
          id
          username
          profile {
            firstName
            lastName
            cellphone
            locale
            profilePicture
          }
          memberships {
            role
            business {
              id
              name
              countryCode
              legalName
              tenant
              number
              type
              logoUrl
              contact {
                name
                phone
                address
                email
              }
              capabilities {
                restaurant
                serviceProvider
                supplier
                bookkeeping
              }
            }
            tenant {
              id
              type
            }
          }
        }
        completedByNew {
          id
          email
          status
          given_name
          family_name
          locale
          picture
          phone_number
          memberships {
            id
            role
            businessId
            userId
          }
          firstName
          lastName
          profilePictureUrl
        }
        activeAt
        activeBy: activeByNew {
          id
          email
          status
          given_name
          family_name
          locale
          picture
          phone_number
          firstName
          lastName
          profilePictureUrl
        }
        domain
        data
        reviewRequired
        delivery {
          document {
            id
            businessId
            supplierId
            fromEmail
            pageCount
            supplier {
              id
              name
              countryCode
              legalName
              tenant
              number
              type
              logoUrl
            }
            issueDate
            type
            documentNumber
            allocationNumber
            orderReference
            deliveryDate
            filePath
            filePathUrl
            structureParams {
              documentNumber
              allocationNumber
              orderReference
              deliveryDate
              issueDate
              netAmount
              taxableAmount
              nonTaxableAmount
              taxRate
              openingBalance
              taxAmount
              totalAmount
              amountDue
              discountRate
              discountAmount
              paymentDueDate
              paidAmount
              paymentDate
              paymentMethod
              referencesFromDate
              referencesToDate
              items {
                name
                sku
                gtin
                price {
                  includingTax
                }
                netPrice
                quantity
                quantityInPackage
                packageQuantity
                discountRate
                discountAmount
                totalDiscount
                totalTax
                totalDeposits
                totalPackages
                totalAmount {
                  includingTax
                  includingDiscount
                }
                reference
              }
              references {
                documentNumber
                issueDate
                orderReference
                deliveryDate
                paymentDueDate
                netAmount
                debitAmount
                creditAmount
                totalAmount
                balance {
                  debit
                }
              }
              rounding {
                debit
              }
              generalCharges {
                name
                amount
              }
            }
            structureToken
            recorded
            isManual
            replicateOf
            hasDeclaration
            locale
            discountRate
            discountAmount
            rounding
            netAmount
            taxableAmount
            nonTaxableAmount
            taxRate
            openingBalance
            amountDue
            taxAmount
            totalAmount
            paymentDueDate
            paidAmount
            paymentDate
            paymentMethod {
              type
              creditCard {
                type
                number
              }
              cheque {
                number
                bank
                branch
                account
                dueDate
              }
              bankTransfer {
                bank
                branch
                account
              }
            }
            referencesFromDate
            referencesToDate
            generalCharges {
              name
              amount
            }
            items {
              sku
              gtin
              name
              quantity
              packageQuantity
              quantityInPackage
              price
              netPrice
              totalAmount
              discountRate
              discountAmount
              totalDiscount
              totalTax
              totalPackages
              totalDeposits
              productId
              reference
            }
            references {
              documentNumber
              orderReference
              deliveryDate
              netAmount
              totalAmount
              debitAmount
              creditAmount
              balance
              issueDate
              paymentDueDate
            }
            ocr {
              status
              originalPath
              originalPathUrl
              correctedPath
              correctedPathUrl
              abby {
                status
                originalPath
                originalPathUrl
                correctedPath
                correctedPathUrl
              }
              google {
                status
                generalPath
                generalPathUrl
                formPath
                formPathUrl
              }
            }
            createdBy
            createdUser {
              id
              email
              status
              given_name
              family_name
              locale
              picture
              phone_number
              firstName
              lastName
              profilePictureUrl
            }
            createdAt
            recordedBy
            recordedAt
            templateId
            source
            isBusinessValid
          }
        }
      }
    }
  }
`;
